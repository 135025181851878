import React from 'react';
import { string, number } from 'prop-types';

import { CircularProgress } from '@material-ui/core';

const Loading = ({
  color = 'secondary',
  size = 100,
  thickness = 2,
}) => <CircularProgress
    size={size}
    color={color}
    thickness={thickness}/>;

Loading.propTypes = {
  color: string,
  size: number,
  thickness: number,
};

export default Loading;
