import React, { useState } from 'react';
import Loadable from '@loadable/component';
import { string } from 'prop-types';
import { navigate } from 'gatsby';

import { buildLogger, LoggableError } from 'logger';
import Loading from 'components/loading';
import StackTrace from 'stacktrace-js';

import { LOYALTY_UPDATE_ROUTE } from 'constants/navigation';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faBinoculars } from '@fortawesome/pro-light-svg-icons';

import { Grid, Typography, Paper } from '@material-ui/core';

import stopVideo from './stop-video';
import useStyles from '../styles';

const LoadablQRCodeReader = Loadable(() => import('components/qr-reader'));

const ScanQrCodeView = ({ passId }) => {
  const styles = useStyles();
  const logger = buildLogger();
  const [loading, setLoading] = useState(false);

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const onScanData = async (serialToken, force = false) => {
    if (serialToken) {
      try {
        setLoading(true);
        const getLoyaltyService = await import('services/loyalty-service');
        const { getLoyaltyBySerialNumber } = getLoyaltyService.default({
          stateContext,
          noticationContext,
        });

        const isLoyalty = await getLoyaltyBySerialNumber({ serialToken, passId });
        if (isLoyalty) {
          navigate(`${LOYALTY_UPDATE_ROUTE}?passId=${passId}`);
        } else {
          setLoading(false);
        }

        if (!force) await stopVideo(logger);
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'QRCodeReader:onError' },
          message,
          trace,
        }));

        if (!force) await stopVideo(logger);

        setLoading(false);
      }
    }
  };

  return (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} sm={11} md={11} lg={10} xl={6}>
        <Paper className={styles.paper} elevation={4}>
        {loading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={10} className={styles.twoTopBottomMargin}>
              <Typography
                variant="h4"
                component="h3"
                color="inherit"
                align="center"
                className={styles.headerGreen}>
                Searching for Card
                <FontAwesomeIcon icon={faBinoculars} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="div"
                  align="right"
                  className={styles.headerSubText}>
                    This should only take a second
                </Typography>
              </Typography>
            </Grid>

            <div className={styles.loadingSpinner}>
              <Loading color="primary" size={150}/>
            </div>
          </Grid>
        ) : (
          <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center">

            <Grid item xs={12} sm={10} className={styles.twoTopBottomMargin}>
              <Typography
                variant="h4"
                component="h3"
                color="inherit"
                align="center"
                className={styles.headerGreen}>
                Scan QR Code
                <FontAwesomeIcon icon={faQrcode} size="lg" className={styles.headerIcon}/>
                <Typography
                  variant="caption"
                  component="div"
                  align="right"
                  className={styles.headerSubText}>
                    Place the camera over the QR code
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
              <LoadablQRCodeReader
                 onScan={onScanData}
                 onError={(err) => {
                   const { stack = '', message = '' } = err;
                   logger.error(new LoggableError({
                     data: { stack, caller: 'QRCodeReader:onError' },
                     message,
                   }));
                 }}
               />
            </Grid>
          </Grid>
        )}
        </Paper>
      </Grid>
    </Grid>
  );
};

ScanQrCodeView.propTypes = {
  passId: string.isRequired,
};

export default ScanQrCodeView;
