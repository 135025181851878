import root from 'window-or-global';
import StackTrace from 'stacktrace-js';
import { LoggableError } from 'logger';

export default async (logger) => {
  if (root.navigator) {
    try {
      const { mediaDevices = {} } = navigator;
      const { getUserMedia = () => {} } = mediaDevices;
      let video = await getUserMedia({ video: true });
      const tracks = video.getVideoTracks();
      for (let i = 0; i < tracks.length; i += 1) {
        const track = tracks[i];
        track.stop();
        track.enabled = false;
      }
      video = null;
    } catch (err) {
      const trace = await StackTrace.get();
      const { stack = '', message = '' } = err;
      logger.error(new LoggableError({
        data: { stack, caller: 'stop:video:onError' },
        message,
        trace,
      }));
    }
  }
};
